//export const API_URL = 'http://207.180.203.17:5000';
export const API_URL = process.env.REACT_APP_API_URL;
//export const BASE = '/api';
export const BASE = '';

/* Auth */
export const API_AUTH = BASE + '/artists/login';
export const API_LOGOUT = BASE + '/artists/logout';

/* Dashboard */
export const API_DASHBOARD_ANALYTICS = BASE + '/dashboard';

/* Home */
export const API_HOME = BASE + '/home';

/* User */
export const API_USERS = BASE + '/users';

/* Artwork */
export const API_MY_ARTWORKS = BASE + '/artworks/my';
export const API_ARTWORKS = BASE + '/artworks';
export const API_ARTWORK_STYLES = API_ARTWORKS + '/styles';
export const API_ARTWORK_TYPES = API_ARTWORKS + '/types';
export const API_ARTWORK_TECHNIQUES = API_ARTWORKS + '/techniques';
export const API_ARTWORK_COLORS = API_ARTWORKS + '/colors';
export const API_ARTWORK_TOPICS = API_ARTWORKS + '/topics';

/* Artists */
export const API_ARTISTS = BASE + '/artists';

/* Order */
export const API_ORDERS = BASE + '/orders';
export const API_RECEIVED_ORDERS = BASE + '/orders/received';
export const API_PLACED_ORDERS = BASE + '/orders/placed';

/* Legal */
export const API_PRIVACY_POLICY = BASE + '/legal/privacy-policy';
export const API_TERMS_CONDITIONS = BASE + '/legal/terms-conditions';
